import React, { useEffect, useState } from 'react';
import "./Menu.css";
import { Helmet } from 'react-helmet-async';
import CommonBanner from '../../components/CommonBanner/CommonBanner';

import thali from "../../assets/images/menu/thali.png";
import sweet from "../../assets/images/menu/ladoo.png";
import soup from "../../assets/images/menu/soup.png";
import juice from "../../assets/images/menu/juice-glass.png";
import spoons from "../../assets/images/menu/spoon.png";
import avacado from "../../assets/images/menu/Avocado.png";
import burger from "../../assets/images/menu/burger.png";
import paneer from "../../assets/images/menu/8.png";
import maggie from "../../assets/images/menu/10.png";
// import idli from "../../assets/images/menu/9.png";
// import ice from "../../assets/images/menu/7.png";
import momo from "../../assets/images/menu/1.png";
import desi from "../../assets/images/menu/2.png";
import daal from "../../assets/images/menu/3.png";
import bread from "../../assets/images/menu/4.png";
import pizza from "../../assets/images/menu/5.png";
import roll from "../../assets/images/menu/6.png";
import raita from "../../assets/images/menu/11 (1).png";
import rice from "../../assets/images/menu/high-rice.png";

//Non veg
import starters from "../../assets/images/menu/Starters-min.png";
import chickenGravy from "../../assets/images/menu/Chicken-in-gravy-min.png"
import muttonGravy from "../../assets/images/menu/Mutton-in-gravy-min.png";
import chinese from "../../assets/images/menu/Chinese-(-Chicken-fried-momos)-min.png";
import eggs from "../../assets/images/menu/Egg-min.png";
import eggRoll from "../../assets/images/menu/Rolls-min.png";
import burger2 from "../../assets/images/menu/burger2.png"


const Menu = () => {
  const [activeTab, setActiveTab] = useState('veg');
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView();
      }
    }
  }, []);

  return (
    <>
       <Helmet>
        <title> Desilicious Firangi | Explore Our Delicious Multi-Cuisine Menu  </title>
        <meta name="description" content="Discover the Desilicious Firangi menu featuring a wide range of starters, soups, salads, shakes, sweet treats, and main courses. Reserve your table today!" />

        <link rel="canonical" href="https://desiliciousfirangi.com/menu" />
      </Helmet>
      <CommonBanner Heading={"Menu"} Page={"Menu"} />


      <section className="menu-page-section">
        <div className="menu-tabs">
          <div className="container">
            <div className="tab-list">
              <button
                className={`tab-item veg ${activeTab === 'veg' ? 'active' : ''}`}
                onClick={() => handleTabClick('veg')}
              >
                Veg Menu
              </button>
              <button
                className={`tab-item nonVeg ${activeTab === 'nonVeg' ? 'active' : ''}`}
                onClick={() => handleTabClick('nonVeg')}
              >
                Non-Veg Menu
              </button>
            </div>
          </div>
        </div>

        <div className="spoon">
          <img src={spoons} alt="" />
        </div>

        <div className="burger">
          <img src={burger} alt="" />
        </div>

        <div className="avacado">
          <img src={avacado} alt="" />
        </div>
        <div className="container c-p">
          {activeTab === 'veg' ?
            <div className="menu-content-section" id='veg'>
              < div className="menu-content" id='sweet'>
                <div className="menu-detail">
                  <h3 className="menu-title">Sweet Tooth</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Vanilla</div>
                        <div className="item-price">Rs: 80.00</div>
                      </div>
                      <p className="menu-item-desc">2 Scoops</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Strawberry</div>
                        <div className="item-price">Rs: 80.00</div>
                      </div>
                      <p className="menu-item-desc">2 Scoops</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chocolate</div>
                        <div className="item-price">Rs: 100.00</div>
                      </div>
                      <p className="menu-item-desc">2 Scoops</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Gulab Jamun</div>
                        <div className="item-price">Rs: 80.00</div>
                      </div>
                      <p className="menu-item-desc">2 Pcs.</p>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container second">
                    <img src={sweet} alt="thali" />
                  </div>
                </div>
              </div>

              <div className="menu-content" id='soup'>
                <div className="menu-detail">
                  <h3 className="menu-title">Soups</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Cream Of Tomato</div>
                        <div className="item-price">Rs: 120.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Veg. Clear Soup</div>
                        <div className="item-price">Rs: 120.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Hot n Sour</div>
                        <div className="item-price">Rs: 120.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Manchow Soup</div>
                        <div className="item-price">Rs: 130.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Tomato-Dhaniya Ka Shorba</div>
                        <div className="item-price">Rs: 130.00</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container third">
                    <img src={soup} alt="thali" />
                  </div>
                </div>
              </div>

              <div className="menu-content" id='mocktail'>
                <div className="menu-detail">
                  <h3 className="menu-title">Shakes & Mocktails</h3>
                  <div className="menu-items">

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Virigin Mojito</div>
                        <div className="item-price">Rs: 150.00</div>
                      </div>
                      <p className="menu-item-desc">A concoction of lime juice, sugar, ice and mint leaves </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Vanilla</div>
                        <div className="item-price">Rs: 120.00</div>
                      </div>
                      <p className="menu-item-desc">Per Item</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Strawberry Shakes</div>
                        <div className="item-price">Rs: 120.00</div>
                      </div>
                      <p className="menu-item-desc">Per Item</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Strawberry Cooler</div>
                        <div className="item-price">Rs: 140.00</div>
                      </div>
                      <p className="menu-item-desc">A refreshing drink with strawberry in lime juice </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Oreo</div>
                        <div className="item-price">Rs: 150.00</div>
                      </div>
                      <p className="menu-item-desc">Per Item</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chocolate</div>
                        <div className="item-price">Rs: 140.00</div>
                      </div>
                      <p className="menu-item-desc">A refreshing drink with strawberry in lime juice </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Blue Lagoon</div>
                        <div className="item-price">Rs: 140.00</div>
                      </div>
                      <p className="menu-item-desc">A sweet lime drink and blue cutacao </p>
                    </div>

                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container fourth">
                    <img src={juice} alt="thali" />
                  </div>
                </div>
              </div>


              <div className="menu-content" id='raita'>
                <div className="menu-detail">
                  <h3 className="menu-title">Raita - Salad</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Garlic Chutney</div>
                        <div className="item-price">Rs: 80.00</div>
                      </div>
                      <p className="menu-item-desc">50 gms</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Veg. Raita</div>
                        <div className="item-price">Rs: 150.00</div>
                      </div>
                      <p className="menu-item-desc">Per bowl</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Plain Curd</div>
                        <div className="item-price">Rs: 70.00</div>
                      </div>
                      <p className="menu-item-desc">per bowl</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Pineapple Raita</div>
                        <div className="item-price">Rs: 170.00</div>
                      </div>
                      <p className="menu-item-desc">per bowl</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Onion Salad</div>
                        <div className="item-price">Rs: 40.00</div>
                      </div>
                      <p className="menu-item-desc">Per Plate</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Kachumber Salad</div>
                        <div className="item-price">Rs: 100.00</div>
                      </div>
                      <p className="menu-item-desc">Per Item</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Green Salad</div>
                        <div className="item-price">Rs: 80.00</div>
                      </div>
                      <p className="menu-item-desc">Per plate</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Boondi Raita</div>
                        <div className="item-price">Rs: 120.00</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container first">
                    <img src={raita} alt="salaad" />
                  </div>
                </div>
              </div>


              <div className="menu-content" id='paneer-specials'>
                <div className="menu-detail">
                  <h3 className="menu-title">Paneer Specials</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Shahi Paneer</div>
                        <div className="item-price">Rs: 260.00</div>
                      </div>
                      <p className="menu-item-desc">Juicy chunks of paneer cooked in a creamy gravy with a rich consistency & oodles of butter</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Tikka Lababdar</div>
                        <div className="item-price">Rs: 300.00</div>
                      </div>
                      <p className="menu-item-desc">A delicious creamy and flavourful dish made with grilled paneer in a masala gravy </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Lababdar</div>
                        <div className="item-price">Rs: 260.00</div>
                      </div>
                      <p className="menu-item-desc">An all time favourite dish, prepared by using lots of veggies & cream </p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Do Pyaaza</div>
                        <div className="item-price">Rs: 270.00</div>
                      </div>
                      <p className="menu-item-desc">Fresh cottage cheese cooked in onion gravy with fenugreek, onion & butter</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Butter Masala</div>
                        <div className="item-price">Rs: 270.00</div>
                      </div>
                      <p className="menu-item-desc">A creamy and mildly-sweet gravy made with butter, tomatoes, cashews, spices and marinated paneer </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Bhurji</div>
                        <div className="item-price">Rs: 330.00</div>
                      </div>
                      <p className="menu-item-desc">Stir-fried cottage cheese a long with butter, chopped onions, tomatoes, ginger and garlic </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Palak Paneer</div>
                        <div className="item-price">Rs: 250.00</div>
                      </div>
                      <p className="menu-item-desc">A dish consisting of paneer in a thick and delicious gravy made from spinach </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Matar Paneer</div>
                        <div className="item-price">Rs: 250.00</div>
                      </div>
                      <p className="menu-item-desc">Creamy, delicious and rich peas with cottage cheese in a tomato gravy, cooked in special garam masala </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Kadhai Paneer</div>
                        <div className="item-price">Rs: 270.00</div>
                      </div>
                      <p className="menu-item-desc">A semi dry curry made with cottage cheese, capsicum, onions & tomatoes cooked in a spicy masala</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Handi Paneer</div>
                        <div className="item-price">Rs: 280.00</div>
                      </div>
                      <p className="menu-item-desc">One of the most famous mouth-watering spicy dishes from the house of Punjab - diced cottage cheese with capsicum and dried spices  </p>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container second">
                    <img src={paneer} alt="paneer" />
                  </div>
                </div>
              </div>

              <div className="menu-content" id='mini-meals'>
                <div className="menu-detail">
                  <h3 className="menu-title">Mini Meals</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Veg. Manchurian- Fried Rice</div>
                        <div className="item-price">Rs: 150.00</div>
                      </div>
                      <p className="menu-item-desc">Per item</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Veg. Biryani with Raita</div>
                        <div className="item-price">Rs: 150.00</div>
                      </div>
                      <p className="menu-item-desc">per plate</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Rajma- Chawal</div>
                        <div className="item-price">Rs: 120.00</div>
                      </div>
                      <p className="menu-item-desc">Per Item</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Pyaaz Parantha with Curd & Achar</div>
                        <div className="item-price">Rs: 100.00</div>
                      </div>
                      <p className="menu-item-desc">Per piece</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Parantha with Curd & Achar</div>
                        <div className="item-price">Rs: 150.00</div>
                      </div>
                      <p className="menu-item-desc">per piece</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Lachcha Parantha with Paneer Butter Masala</div>
                        <div className="item-price">Rs: 180.00</div>
                      </div>
                      <p className="menu-item-desc">per piece</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Gobhi Parantha with Curd & Achar</div>
                        <div className="item-price">Rs: 120.00</div>
                      </div>
                      <p className="menu-item-desc">Per Item</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Daal- Chawal</div>
                        <div className="item-price">Rs: 110.00</div>
                      </div>
                      <p className="menu-item-desc">Per Item</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chole-Chawal</div>
                        <div className="item-price">Rs: 120.00</div>
                      </div>
                      <p className="menu-item-desc">per plate</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Aloo-Pyaz Parantha with Curd & Achar</div>
                        <div className="item-price">Rs: 110.00</div>
                      </div>
                      <p className="menu-item-desc">Per piece</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Aloo Parantha with Curd & Achar</div>
                        <div className="item-price">Rs: 100.00</div>
                      </div>
                      <p className="menu-item-desc">Per item</p>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container first">
                    <img src={thali} alt="mini-meals" />
                  </div>
                </div>
              </div>

              <div className="menu-content" id='light-weight'>
                <div className="menu-detail">
                  <h3 className="menu-title">Light Weight</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Pao Bhaji</div>
                        <div className="item-price">Rs: 100.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Maggie Masala</div>
                        <div className="item-price">Rs: 60.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Grilled Veg. Sandwich</div>
                        <div className="item-price">Rs: 75.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Grilled Veg. Cheese Sandwich</div>
                        <div className="item-price">Rs: 100.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Extra Pao</div>
                        <div className="item-price">Rs: 30.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Extra Bhatura</div>
                        <div className="item-price">Rs: 40.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chole Bhature</div>
                        <div className="item-price">Rs: 180.00</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container first">
                    <img src={maggie} alt="light" />
                  </div>
                </div>
              </div>


              <div className="menu-content" id='chef-special'>
                <div className="menu-detail">
                  <h3 className="menu-title">Desi Firangi Chef Special</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Dahi Kebab</div>
                        <div className="item-price">Rs: 200.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer 65</div>
                        <div className="item-price">Rs: 175.00</div>
                      </div>
                    </div>
                    
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Popcorn</div>
                        <div className="item-price">Rs: 155.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Cheese Balls</div>
                        <div className="item-price">Rs: 190.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Tandoori Mushroom</div>
                        <div className="item-price">Rs: 150.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Tandoori Aloo</div>
                        <div className="item-price">Rs: 170.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chilly Chaap</div>
                        <div className="item-price">Rs: 150.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Junior Burgers</div>
                        <div className="item-price">Rs: 120.00</div>
                      </div>
                      <p className="menu-item-desc">4 small burgers</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Grilled Panneer With <br /> Vegetables In Barbque Sauce </div>
                        <div className="item-price">Rs: 320.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Gobhi Manchurian</div>
                        <div className="item-price">Rs: 160.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">French Aloo Roll</div>
                        <div className="item-price">Rs: 130.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Schwezwan Rajma-Rice <br /> Wrap With Carmel Sauce</div>
                        <div className="item-price">Rs: 280.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Vegetable Saute'</div>
                        <div className="item-price">Rs: 210.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Saute'</div>
                        <div className="item-price">Rs: 150.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Tandoori Platter</div>
                        <div className="item-price">Rs: 250.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Nachos Chaat</div>
                        <div className="item-price">Rs: 180.00</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container first">
                    <img src={burger2} alt="burger" />
                  </div>
                </div>
              </div>



              <div className="menu-content" id='high-rice'>
                <div className="menu-detail">
                  <h3 className="menu-title">High Rice</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Veg. Pulao</div>
                        <div className="item-price">Rs: 160.00</div>
                      </div>
                      <p className="menu-item-desc">The anytime recipe of basic vegetables added to rice to make it just right </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Veg. Biryani</div>
                        <div className="item-price">Rs: 190.00</div>
                      </div>
                      <p className="menu-item-desc">Golden-hued long-grain basmati adorned with fresh vegetables and coveted spice mix </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Plain Rice</div>
                        <div className="item-price">Rs: 110.00</div>
                      </div>
                      <p className="menu-item-desc">Rice at its best</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Matar Pulao</div>
                        <div className="item-price">Rs: 150.00</div>
                      </div>
                      <p className="menu-item-desc">The blended flavours of green peas with rice </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Lemon Rice</div>
                        <div className="item-price">Rs: 120.00</div>
                      </div>
                      <p className="menu-item-desc">The aroma & richness of lemon juice to make the rice perfect </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Jodhpuri Pulao</div>
                        <div className="item-price">Rs: 190.00</div>
                      </div>
                      <p className="menu-item-desc">The awesome flavours of rice, vegetables and gram flour dumplings cooked in dum, straight from the 'blue city </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Jeera Rice</div>
                        <div className="item-price">Rs: 140.00</div>
                      </div>
                      <p className="menu-item-desc">The tempering of jeera on white rice </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Hyderabadi Biryani</div>
                        <div className="item-price">Rs: 200.00</div>
                      </div>
                      <p className="menu-item-desc">Inspired by the Nawaabi style, fresh crisp vegetables marinated in a spicier blend layered with golden-hued basmati </p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Curd Rice</div>
                        <div className="item-price">Rs: 120.00</div>
                      </div>
                      <p className="menu-item-desc">The flavours of South Indian tempering of curd in rice </p>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container first">
                    <img src={rice} alt="kerala-express" />
                  </div>
                </div>
              </div>


              <div className="menu-content" id='drink'>
                <div className="menu-detail">
                  <h3 className="menu-title">Drink in Sync</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Tea</div>
                        <div className="item-price">Rs: 30.00</div>
                      </div>
                      <p className="menu-item-desc">Per tea</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Lassi</div>
                        <div className="item-price">Rs: 70.00</div>
                      </div>
                      <p className="menu-item-desc">Per item</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Fresh Lime Water</div>
                        <div className="item-price">Rs: 60.00</div>
                      </div>
                      <p className="menu-item-desc">Per glass</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Fresh Lime Soda</div>
                        <div className="item-price">Rs: 80.00</div>
                      </div>
                      <p className="menu-item-desc">Per glass </p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Cold Coffee</div>
                        <div className="item-price">Rs: 90.00</div>
                      </div>
                      <p className="menu-item-desc">Per Cold coffee</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Cold Coffee with Ice cream</div>
                        <div className="item-price">Rs: 130.00</div>
                      </div>
                      <p className="menu-item-desc">Per Cold coffee</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Coffee</div>
                        <div className="item-price">Rs: 50.00</div>
                      </div>
                      <p className="menu-item-desc">Per coffee</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chhach</div>
                        <div className="item-price">Rs: 50.00</div>
                      </div>
                      <p className="menu-item-desc">Per Item</p>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container fourth">
                    <img src={juice} alt="thali" />
                  </div>
                </div>
              </div>


              <div className="menu-content" id='dragons-land'>
                <div className="menu-detail">
                  <h3 className="menu-title">Dragons Land</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Veg. Manchurian</div>
                        <div className="item-price">Rs: 190.00</div>
                      </div>
                      <p className="menu-item-desc">Manchurian made with chopped vegetables & deep fried, served in soya sauce</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Veg. Hakka Noodles</div>
                        <div className="item-price">Rs: 90.00</div>
                      </div>
                      <p className="menu-item-desc">Noodles served with mix vegetables</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Veg. Chowmein</div>
                        <div className="item-price">Rs: 80.00</div>
                      </div>
                      <p className="menu-item-desc">Noodles served with vegetables and soya souce</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Veg Momos ( 8 pcs )</div>
                        <div className="item-price">Rs: 80.00</div>
                      </div>
                      <p className="menu-item-desc">Steamed dumplings stuffed with a lightly spiced vegetable filling</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Tandoori Momos</div>
                        <div className="item-price">Rs: 130.00</div>
                      </div>
                      <p className="menu-item-desc">Tandoori Momos ( 8 pcs ) 120 Momos marinated with tandoori masala cooked over charcoal</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Crispy Momos</div>
                        <div className="item-price">Rs: 130.00</div>
                      </div>
                      <p className="menu-item-desc">8 pcs</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Soya Momos</div>
                        <div className="item-price">Rs: 130.00</div>
                      </div>
                      <p className="menu-item-desc">8 pcs</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Crispy Corn</div>
                        <div className="item-price">Rs: 160.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Spring Rolls</div>
                        <div className="item-price">Rs: 170.00</div>
                      </div>
                      <p className="menu-item-desc">Shredded cabbage, spring onions & carrots tossed in herbs rolled and fried to perfection</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Honey Chilly Potato</div>
                        <div className="item-price">Rs: 180.00</div>
                      </div>
                      <p className="menu-item-desc">Crispy fried potatoes tossed with our in-house honey chilly sauce</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Fried Rice</div>
                        <div className="item-price">Rs: 170.00</div>
                      </div>
                      <p className="menu-item-desc">A dish of cooked rice that has been stir-fried, mixed with vegetables and sauces</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Fried Momos ( 8 pcs )</div>
                        <div className="item-price">Rs: 100.00</div>
                      </div>
                      <p className="menu-item-desc">Crispy & delicious deep fried dumplings stuffed with crunchy & mildly-spiced vegetables</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chinese Bhel</div>
                        <div className="item-price">Rs: 100.00</div>
                      </div>
                      <p className="menu-item-desc">A crispy, sweet, spicy & tangy fusion snack from the indo-chinese cuisine</p>
                    </div>
                  </div>

                  <div className="menu-item">
                    <div className="item-detail">
                      <div className="item-name">Chilly Potato</div>
                      <div className="item-price">Rs: 140.00</div>
                    </div>
                    <p className="menu-item-desc">An indo-chinese starter made with crispy fried potatoes tossed in slightly spicy, sweet & sour chilly sauce</p>
                  </div>

                  <div className="menu-item">
                    <div className="item-detail">
                      <div className="item-name">Chilly Paneer (Gravy)</div>
                      <div className="item-price">Rs: 240.00</div>
                    </div>
                    <p className="menu-item-desc">Diced cottage cheese fried in our in-house chilly garlic sauce and gravy</p>
                  </div>

                  <div className="menu-item">
                    <div className="item-detail">
                      <div className="item-name">Chilly Paneer (Dry)</div>
                      <div className="item-price">Rs: 220.00</div>
                    </div>
                    <p className="menu-item-desc">Diced cottage cheese fried in our in-house chilly garlic sauce</p>
                  </div>

                  <div className="menu-item">
                    <div className="item-detail">
                      <div className="item-name">Chilly Garlic Noodles</div>
                      <div className="item-price">Rs: 130.00</div>
                    </div>
                  </div>

                </div>
                <div className="menu-image">
                  <div className="image-container second">
                    <img src={momo} alt="dragons-land" />
                  </div>
                </div>
              </div>


              <div className="menu-content" id='desi-tadka'>
                <div className="menu-detail">
                  <h3 className="menu-title">Desi Tadka</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Veg. Jalfrezi</div>
                        <div className="item-price">Rs: 270.00</div>
                      </div>
                      <p className="menu-item-desc">Vegetables cooked in their juices then deep fried in oil to give it a sumptuous flavour</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Sev Tamatar</div>
                        <div className="item-price">Rs: 190.00</div>
                      </div>
                      <p className="menu-item-desc">An authentic recipe from the Rajasthani cuisine prepared with Ratlami Sev Namkeen.</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Rajasthani Masala Gatte</div>
                        <div className="item-price">Rs: 200.00</div>
                      </div>
                      <p className="menu-item-desc">Curry steamed dumplings made from gram flour cooked in a spiced yoghurt sauce</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Mushroom Masala</div>
                        <div className="item-price">Rs: 280.00</div>
                      </div>
                      <p className="menu-item-desc">The awesomeness of mushrooms sauteed in a thick gravy</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Mix Veg</div>
                        <div className="item-price">Rs: 250.00</div>
                      </div>
                      <p className="menu-item-desc">Mixed vegetables cooked together in their juices in a traditional indian onion-tomato gravy</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Methi Malai Matar</div>
                        <div className="item-price">Rs: 300.00</div>
                      </div>
                      <p className="menu-item-desc">A sumptuous dish with methi leaves and green peas in a rich creamy gravy</p>
                    </div>


                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Matar Mushroom</div>
                        <div className="item-price">Rs: 260.00</div>
                      </div>
                      <p className="menu-item-desc">Handful of fresh mushroom & green peas cooked in cashew and tomato gravy</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Malai Kofta (Yellow/White)</div>
                        <div className="item-price">Rs: 250.00 / 280.00</div>
                      </div>
                      <p className="menu-item-desc">Mouth melting delicious kofta in rich creamy shahi gravy</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Kabuli Chane Masaledar</div>
                        <div className="item-price">Rs: 250.00</div>
                      </div>
                      <p className="menu-item-desc">Protein-packed and fuss-free dish cooked in red gravy</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Gobhi Masala</div>
                        <div className="item-price">Rs: 200.00</div>
                      </div>
                      <p className="menu-item-desc">The crunchiness of cauliflower in onion & tomato gravy</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Dum Aloo</div>
                        <div className="item-price">Rs: 230.00</div>
                      </div>
                      <p className="menu-item-desc">Whole baby potatoes in onion & tomato-rich gravy</p>
                    </div>


                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Aloo Pyaz</div>
                        <div className="item-price">Rs: 240.00</div>
                      </div>
                      <p className="menu-item-desc">The very Indian flavours of deep-fried onion & potato in desi style rich gravy</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Aloo Matar</div>
                        <div className="item-price">Rs: 190.00</div>
                      </div>
                      <p className="menu-item-desc">The double flavours of potatoes and green peas simmered to make it flavourful</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Aloo Jeera</div>
                        <div className="item-price">Rs: 180.00</div>
                      </div>
                      <p className="menu-item-desc">Fresh aloo fried with cumin seeds & indian spices, flavoured with red chilly powder, ginger, coriander powder</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Aloo Gobhi</div>
                        <div className="item-price">Rs: 190.00</div>
                      </div>
                      <p className="menu-item-desc">Cauliflower & potatoes cooked in an exotic blend of spices with tomatoes and garnished with coriander leaves</p>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container second">
                    <img src={desi} alt="desi-tadka" />
                  </div>
                </div>
              </div>


              <div className="menu-content" id='daal-desilicious'>
                <div className="menu-detail">
                  <h3 className="menu-title">Daal Desilicious</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Daal Tadka</div>
                        <div className="item-price">Rs: 200.00</div>
                      </div>
                      <p className="menu-item-desc">Mixed daals lastly tempered with butter fried spices and herbs</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Daal Makhani</div>
                        <div className="item-price">Rs: 230.00</div>
                      </div>
                      <p className="menu-item-desc">Auhtentic punjabi black gram lentil and kidney beans cooked over night, garnished with butter & cream</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Daal Fry</div>
                        <div className="item-price">Rs: 180.00</div>
                      </div>
                      <p className="menu-item-desc">A comforting and delicious preparation made with mixed yellow daals cooked and tempered with onions, chillies and more</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Daal Dhaba</div>
                        <div className="item-price">Rs: 230.00</div>
                      </div>
                      <p className="menu-item-desc">A rich, creamy slow-cooked mixed yellow and whole black gram lentils recipe with tomato puree, butter & cream</p>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container first">
                    <img src={daal} alt="daal" />
                  </div>
                </div>
              </div>



              <div className="menu-content" id='breads'>
                <div className="menu-detail">
                  <h3 className="menu-title">Breads</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Tawa Roti (P/B)</div>
                        <div className="item-price">Rs: 15.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Tawa Roti (P/B)</div>
                        <div className="item-price">Rs: 20.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Tandoori (P/B)</div>
                        <div className="item-price">Rs: 12.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Tandoori (P/B)</div>
                        <div className="item-price">Rs: 15.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Stuffed Naan</div>
                        <div className="item-price">Rs: 130.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Plain Naan</div>
                        <div className="item-price">Rs: 45.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Missi Roti</div>
                        <div className="item-price">Rs: 60.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Roomali Roti</div>
                        <div className="item-price">Rs: 25.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Lachcha Parantha</div>
                        <div className="item-price">Rs: 55.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Garlic Naan</div>
                        <div className="item-price">Rs: 80.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Butter Naan</div>
                        <div className="item-price">Rs: 70.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Bread Basket</div>
                        <div className="item-price">Rs: 190.00</div>
                      </div>
                      <p className="menu-item-desc">2 Tandoori, 2 Butter Naan, 1 Lachcha Parantha</p>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container second">
                    <img src={bread} alt="bread" />
                  </div>
                </div>
              </div>

              <div className="menu-content" id='bon-appett'>
                <div className="menu-detail">
                  <h3 className="menu-title">Bon Appe'tit(Italian)</h3>
                  <div className="menu-items">
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Pizza Overloaded</div>
                        <div className="item-price">Rs: 250.00</div>
                      </div>
                      <p className="menu-item-desc">Pizza overloaded with cheese loadsof toppings</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Pizza O.T.C</div>
                        <div className="item-price">Rs: 200.00</div>
                      </div>
                      <p className="menu-item-desc">Classic italian pizza with fresh vegetables (onion, tomato, capsicum) garnished with cheese</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Pink Pasta</div>
                        <div className="item-price">Rs: 200.00</div>
                      </div>
                      <p className="menu-item-desc">Pasta in mix of red &white sauce with cheese, exotic vegetables, garlic tossed in italian herbs</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Cheezza</div>
                        <div className="item-price">Rs: 170.00</div>
                      </div>
                      <p className="menu-item-desc">Margherita pizza with dollops of cheese</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Cheese Garlic Bread</div>
                        <div className="item-price">Rs: 140.00</div>
                      </div>
                      <p className="menu-item-desc">Bread roasted with garlic and herbs in a cheesy topping</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Arrabiata Pasta</div>
                        <div className="item-price">Rs: 180.00</div>
                      </div>
                      <p className="menu-item-desc">A tasty and tangy pasta cooked with cheese veggies & herbs in red sauce</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Alfredo Pasta</div>
                        <div className="item-price">Rs: 190.00</div>
                      </div>
                      <p className="menu-item-desc">Pasta cooked in a creamy white sauce along with cheese, veggies & herbs</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chinglings </div>
                        <div className="item-price">Rs: 80.00</div>
                      </div>
                      <p className="menu-item-desc">5 Inch</p>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container first">
                    <img src={pizza} alt="pizza" />
                  </div>
                </div>
              </div>


              <div className="menu-content" id='appetizers'>
                <div className="menu-detail">
                  <h3 className="menu-title">Appetizers</h3>
                  <div className="menu-items">

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Veg. Rolls</div>
                        <div className="item-price">Rs: 130.00</div>
                      </div>
                      <p className="menu-item-desc">Delicious rolls stuffed with a spiced mix veg stuffing</p>
                    </div>


                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Roasted Papad</div>
                        <div className="item-price">Rs: 30.00</div>
                      </div>
                      <p className="menu-item-desc">per item</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Tikka Rolls</div>
                        <div className="item-price">Rs: 180.00</div>
                      </div>
                      <p className="menu-item-desc">Paneer tikka tossed in onion rings, curd & garam masala served with mint chutney</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Tikka</div>
                        <div className="item-price">Rs: 250.00</div>
                      </div>
                      <p className="menu-item-desc">Made from chunks of paneer marinated in spices and grilled in tandoor</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Rolls</div>
                        <div className="item-price">Rs: 150.00</div>
                      </div>
                      <p className="menu-item-desc">Delicious rolls stuffed with grated paneer, sauted in spicy masala</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Pakodas</div>
                        <div className="item-price">Rs: 180.00</div>
                      </div>
                      <p className="menu-item-desc">Crispy cottage cheese tossed with fashioned salt & pepper</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Paneer Malai Tikka</div>
                        <div className="item-price">Rs: 270.00</div>
                      </div>
                      <p className="menu-item-desc">A unique blend of paneer and cream marinated in herbs and spices, grilled in a tandoor</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Mix Veg Pakodas</div>
                        <div className="item-price">Rs: 160.00</div>
                      </div>
                      <p className="menu-item-desc">Crispy fried vegetables tossed with fashioned salt & pepper</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Masala Papad</div>
                        <div className="item-price">Rs: 60.00</div>
                      </div>
                      <p className="menu-item-desc">Roasted papad with finely diced onions, tomatoes & our in-house masala</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Hara Bhara Kebab</div>
                        <div className="item-price">Rs: 180.00</div>
                      </div>
                      <p className="menu-item-desc">Mixture of beans, carrots, green peas, paneer & dry fruits, deep fried & served with mint chutney</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Fried Masala Papad</div>
                        <div className="item-price">Rs: 70.00</div>
                      </div>
                      <p className="menu-item-desc">Fried papad with finely diced onions, tomatoes & our in-house masala</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">French Fries</div>
                        <div className="item-price">Rs: 150.00</div>
                      </div>
                      <p className="menu-item-desc">Thinly sliced potatoes are deep fried till they are crisp on all sides, sprinkled with salt & pepper</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Soya Chaap</div>
                        <div className="item-price">Rs: 190.00</div>
                      </div>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Soya Malai Chaap</div>
                        <div className="item-price">Rs: 220.00</div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container second">
                    <img src={roll} alt="roll" />
                  </div>
                </div>
              </div>
            </div >
            : <div className="menu-content-section" id='nonVeg'>
              <div className="menu-content" id='starters'>
                <div className="menu-detail">
                  <h3 className="menu-title">Starters</h3>
                  <div className="menu-items">

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Tandoori Chicken</div>
                        <div className="item-price">Rs: 250.00</div>
                      </div>
                      <p className="menu-item-desc">Half</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Tandoori Chicken</div>
                        <div className="item-price">Rs: 480.00</div>
                      </div>
                      <p className="menu-item-desc">Full</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Afghani Chicken</div>
                        <div className="item-price">Rs: 300.00</div>
                      </div>
                      <p className="menu-item-desc">Half</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Afghani Chicken</div>
                        <div className="item-price">Rs: 500.00</div>
                      </div>
                      <p className="menu-item-desc">Full</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Tikka</div>
                        <div className="item-price">Rs: 300.00</div>
                      </div>
                      <p className="menu-item-desc">8pcs</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Malai Tikka</div>
                        <div className="item-price">Rs: 360.00</div>
                      </div>
                      <p className="menu-item-desc">8pcs</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Roll</div>
                        <div className="item-price">Rs: 300.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Tikka Roll</div>
                        <div className="item-price">Rs: 320.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Mirchi</div>
                        <div className="item-price">Rs:  ___.__</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Saute' With Vegetables</div>
                        <div className="item-price">Rs: ___.__</div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container first">
                    <img src={starters} alt="roll" />
                  </div>
                </div>
              </div>

              <div className="menu-content" id='chickken-in-gravy'>
                <div className="menu-detail">
                  <h3 className="menu-title">Chickken In Gravy</h3>
                  <div className="menu-items">

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Butter Chicken</div>
                        <div className="item-price">Rs: 250.00</div>
                      </div>
                      <p className="menu-item-desc">Quarter</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Butter Chicken</div>
                        <div className="item-price">Rs: 400.00</div>
                      </div>
                      <p className="menu-item-desc">Half</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Butter Chicken</div>
                        <div className="item-price">Rs: 650.00</div>
                      </div>
                      <p className="menu-item-desc">Full</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Kadhai Chicken</div>
                        <div className="item-price">Rs: 280.00</div>
                      </div>
                      <p className="menu-item-desc">Quarter</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Kadhai Chicken</div>
                        <div className="item-price">Rs: 430.00</div>
                      </div>
                      <p className="menu-item-desc">Half</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Kadhai Chicken</div>
                        <div className="item-price">Rs: 650.00</div>
                      </div>
                      <p className="menu-item-desc">Full</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Curry</div>
                        <div className="item-price">Rs: 220.00</div>
                      </div>
                      <p className="menu-item-desc">Quarter</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Curry</div>
                        <div className="item-price">Rs: 350.00</div>
                      </div>
                      <p className="menu-item-desc">Half</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Curry</div>
                        <div className="item-price">Rs: 600.00</div>
                      </div>
                      <p className="menu-item-desc">Full</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Masala</div>
                        <div className="item-price">Rs: 230.00</div>
                      </div>
                      <p className="menu-item-desc">Quarter</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Masala</div>
                        <div className="item-price">Rs: 400.00</div>
                      </div>
                      <p className="menu-item-desc">Half</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Masala</div>
                        <div className="item-price">Rs: 670.00</div>
                      </div>
                      <p className="menu-item-desc">Full</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Handi Chicken</div>
                        <div className="item-price">Rs: 260.00</div>
                      </div>
                      <p className="menu-item-desc">Quarter</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Handi Chicken</div>
                        <div className="item-price">Rs: 450.00</div>
                      </div>
                      <p className="menu-item-desc">Half</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Handi Chicken</div>
                        <div className="item-price">Rs: 650.00</div>
                      </div>
                      <p className="menu-item-desc">Full</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Tikka Masala</div>
                        <div className="item-price">Rs: 260.00</div>
                      </div>
                      <p className="menu-item-desc">Quarter</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Tikka Masala</div>
                        <div className="item-price">Rs: 450.00</div>
                      </div>
                      <p className="menu-item-desc">Half</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Tikka Masala</div>
                        <div className="item-price">Rs: 650.00</div>
                      </div>
                      <p className="menu-item-desc">Full</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Egg Curry (Veg Gravy)</div>
                        <div className="item-price">Rs: 160.00</div>
                      </div>
                      <p className="menu-item-desc">Half</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Egg Curry (Veg Gravy)</div>
                        <div className="item-price">Rs: 300.00</div>
                      </div>
                      <p className="menu-item-desc">Full</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Egg Curry (Non-veg Gravy)</div>
                        <div className="item-price">Rs: 180.00</div>
                      </div>
                      <p className="menu-item-desc">Half</p>
                    </div>
                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Egg Curry (Non-veg Gravy)</div>
                        <div className="item-price">Rs: 320.00</div>
                      </div>
                      <p className="menu-item-desc">Full</p>
                    </div>

                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container first">
                    <img src={chickenGravy} alt="roll" />
                  </div>
                </div>
              </div>





              <div className="menu-content" id='mutton-in-gravy'>
                <div className="menu-detail">
                  <h3 className="menu-title">Mutton In Gravy (On Order)</h3>
                  <div className="menu-items">

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Handi Meat</div>
                        <div className="item-price">Rs: 1450.00</div>
                      </div>
                      <p className="menu-item-desc">In Mustard Oil</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Handi Meat</div>
                        <div className="item-price">Rs: 1950.00</div>
                      </div>
                      <p className="menu-item-desc">In Desi Ghee</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Mutton Curry</div>
                        <div className="item-price">Rs: 1400.00</div>
                      </div>
                      <p className="menu-item-desc">In Mustard Oil</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Mutton Curry</div>
                        <div className="item-price">Rs: 1950.00</div>
                      </div>
                      <p className="menu-item-desc">In Desi Ghee</p>
                    </div>


                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Laal Mass</div>
                        <div className="item-price">Rs: 1600.00</div>
                      </div>
                      <p className="menu-item-desc">In Mustard Oil</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Laal Mass</div>
                        <div className="item-price">Rs: 2100.00</div>
                      </div>
                      <p className="menu-item-desc">In Desi Ghee</p>
                    </div>


                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Mutton Rogan Josh</div>
                        <div className="item-price">Rs: 1600.00</div>
                      </div>
                      <p className="menu-item-desc">In Mustard Oil</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Mutton Rogan Josh</div>
                        <div className="item-price">Rs: 2100.00</div>
                      </div>
                      <p className="menu-item-desc">In Desi Ghee</p>
                    </div>

                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container second">
                    <img src={muttonGravy} alt="roll" />
                  </div>
                </div>
              </div>


              <div className="menu-content" id='chinese'>
                <div className="menu-detail">
                  <h3 className="menu-title">Chinese</h3>
                  <div className="menu-items">

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Chowmein</div>
                        <div className="item-price">Rs: 130.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicekn Momos</div>
                        <div className="item-price">Rs: 160.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Fried Momos</div>
                        <div className="item-price">Rs: 170.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Crispy Chicken Momos</div>
                        <div className="item-price">Rs: 200.00</div>
                      </div>
                    </div>


                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicekn Fried Rice</div>
                        <div className="item-price">Rs: 200.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chilly Chicken (Dry)</div>
                        <div className="item-price">Rs: 300.00</div>
                      </div>
                    </div>


                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chilly Chicken (Gravy)</div>
                        <div className="item-price">Rs: 320.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Biryani</div>
                        <div className="item-price">Rs: 250.00</div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container first">
                    <img src={chinese} alt="roll" />
                  </div>
                </div>
              </div>


              <div className="menu-content" id='eggs'>
                <div className="menu-detail">
                  <h3 className="menu-title">Eggs</h3>
                  <div className="menu-items">

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Boiled Eggs</div>
                        <div className="item-price">Rs: 40.00</div>
                      </div>
                      <p className="menu-item-desc">2 Eggs</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Omlette</div>
                        <div className="item-price">Rs: 60.00</div>
                      </div>
                      <p className="menu-item-desc">2 Eggs</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Omlette</div>
                        <div className="item-price">Rs: 80.00</div>
                      </div>
                      <p className="menu-item-desc">2 Eggs, Butter</p>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Egg Biryani</div>
                        <div className="item-price">Rs: 230.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Egg Fried Rice</div>
                        <div className="item-price">Rs: 190.00</div>
                      </div>
                    </div>


                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chilly Eggs</div>
                        <div className="item-price">Rs: 130.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Egg Kofta</div>
                        <div className="item-price">Rs: 70.00</div>
                      </div>
                    </div>


                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Egg Bhurji</div>
                        <div className="item-price">Rs: 80.00</div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container second">
                    <img src={eggs} alt="roll" />
                  </div>
                </div>
              </div>



              <div className="menu-content" id='rolls'>
                <div className="menu-detail">
                  <h3 className="menu-title">Rolls</h3>
                  <div className="menu-items">

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Roll</div>
                        <div className="item-price">Rs: 180.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Chicken Tikka Roll</div>
                        <div className="item-price">Rs: 220.00</div>
                      </div>
                    </div>

                    <div className="menu-item">
                      <div className="item-detail">
                        <div className="item-name">Egg Roll</div>
                        <div className="item-price">Rs: 140.00</div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="menu-image">
                  <div className="image-container first">
                    <img src={eggRoll} alt="roll" />
                  </div>
                </div>
              </div>


            </div>}
        </div >
      </section >
    </>
  )
}

export default Menu