import React from 'react';
import "./Home.css";
import HomeBannerSlider from '../../components/HomeBannerSlider/HomeBannerSlider';

import img from "../../assets/images/home/about-img.png";
import leaf from "../../assets/images/home/pngegg 1.png";

import berry from "../../assets/images/home/Blueberry.png";
import tomato from "../../assets/images/home/tomato.png";

import c1 from "../../assets/images/home/north-indian.png";
import c2 from "../../assets/images/home/chinese.png";
import c3 from "../../assets/images/home/south-indian.png";
import c4 from "../../assets/images/home/contential.png";
import c5 from "../../assets/images/home/breakfast-hotel 1.png";
import c6 from "../../assets/images/home/desserts.png";


import CommonHeading from '../../components/CommonHeading/CommonHeading';
import CommonBtn from '../../components/CommonBtn/CommonBtn';
import CategoryCard from '../../components/CategoryCard/CategoryCard';
import MenuSectionSlider from '../../components/MenuSectionSlider/MenuSectionSlider';
import ReservationSection from '../../components/ReservationSection/ReservationSection';
import CommonSection from '../../components/CommonSection/CommonSection';
import Testimonials from '../../components/Testimonials/Testimonials';
import { Helmet } from 'react-helmet-async';


const Home = () => {
    return (
        <>
            <Helmet>
                <title>Desilicious Firangi | The Best Multi-Cuisine Restaurant In Jaipur</title>
                <meta name="description" content="Desilicious Firangi is a perfect multi-cuisine restaurant situated on the outskirts of Jaipur at Patrakar Colony is an ideal place for a plethora of events. Visit us now!" />
                
                <link rel="canonical" href="https://desiliciousfirangi.com/" />
            </Helmet>

            <HomeBannerSlider />


            {/* About Section */}
            <section id='home-about' className="about-section">
                <div className="container">
                    <div className="about-section-card c-p">
                        <div className="about-section-card-img">
                            <img src={img} alt="about" />
                        </div>
                        <div className="about-section-card-content">
                            <CommonHeading shortTitle={"About"} title={"Desilicious Firangi"} />
                            <p className="about-section-card-desc">We are a group of foodies with an admiration for quality and tasty food. As born Jaipurites, we have come up with the most ideal menu that soothes you. Desilicious Firangi, better known as Desi Firangi, is situated in Patrakar Colony, Mansarovar, Jaipur. The perfect setting, along with the multi-cuisine food, offers a mesmerizing fusion of desi and international lifestyles. Book your table for tonight and experience a unique style of eatery only at Desilicious Firangi!</p>
                            <CommonBtn text={"Read More"} url={"/about"} />
                        </div>
                    </div>
                </div>
                <div className="g-leaf">
                    <img src={leaf} alt="leaf" />
                </div>
            </section>


            {/* Category Section */}
            <section className="category-section">
                <div className="container">
                    <div className="category-section-cards c-p">
                        <CategoryCard src={c1} title={"North-India"} desc={"Selection of the most mouth-watering delicacies from North India"} />
                        <CategoryCard src={c2} title={"Chinese"} desc={"Straight out of the dragon’s land delivered to your plate."} />
                        <CategoryCard src={c3} title={"South-India"} desc={"Healthy and flavorsome list of personally selected cuisines from the south of the Vindhyachal range."} />
                        <CategoryCard src={c4} title={"Continental"} desc={"Lip-smacking a-la-carte from the European continent"} />
                        <CategoryCard src={c5} title={"Breakfast"} desc={"Nourishing and healthful meals to start the day."} />
                        <CategoryCard src={c6} title={"Desserts"} desc={"Refreshing picks selected to rejuvenate your senses."} />
                    </div>
                </div>
            </section>


            {/* Menu Section */}
            <section className="menu-section c-s">
                <div className="blueberry">
                    <img src={berry} alt="bg" />
                </div>
                <div className="container c-p">
                    <div className="menu-section-heading">
                        <CommonHeading shortTitle={"Online Food Delivery in Jaipur"} title={"Check Our Menu"} />
                    </div>
                    <div className="menu-section-slider">
                        <MenuSectionSlider />
                    </div>
                </div>
                <div className="tomato">
                    <img src={tomato} alt="bg" />
                </div>
            </section>



            {/* Reservation Section */}
            <ReservationSection />


            {/* Common Section */}
            <CommonSection />


            {/* Testimonials */}
            <Testimonials />
        </>
    )
}

export default Home