// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
    return (
        <div className="not-found-wrapper">
            <div className="not-content-box">
                <h1 className="not-found-title">404</h1>
                <h2 className="not-subtitle">Uh-oh! The kitchen is empty. 😢</h2>
                <p className="not-description">Looks like we’ve run out of ingredients for this page!</p>
                <p>Let’s get you back to something more delicious:</p>
                <Link to="/menu" className="not-menu-btn">Explore our Menu</Link>
                <Link to="/" className="not-home-btn">Back to Home</Link>
            </div>
        </div>
    );
};

export default NotFound;
